import { motion } from 'framer-motion';
import React from 'react';

const CardHeader = ({title, subTitle, bgColor}) => {
  return (
    <motion.div initial={{opacity: 0}} whileInView={{ opacity:[0, 0, 0.5, 1] }} transition={{duration: 0.6}} viewport={{once: true}} className={`${bgColor} p-4 w-full text-center`} >
        <h2 className='text-xl font-extrabold tracking-widest'>
            {title}
        </h2>
        <p className='text-md lg:text-lg'>{subTitle}</p>
    </motion.div>
  );
};

export default CardHeader;
