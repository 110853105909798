import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import React from 'react';

const CardDesc = ({title, text, borderColor, textColor, titleIcon, descList, listIcon, optionTitle, optionTitleIcon, optionList, optionListIcon, children}) => {
  return (
    <motion.div initial={{opacity: 0}} whileInView={{ y:[20, 0], opacity:[0, 0, 0.5, 1] }} transition={{duration: 0.8}} viewport={{once: true}} className='p-4 w-full text-lg' >
        <h3 className={`mb-2 border-b ${borderColor} text-red-400 font-bold`}>
            <FontAwesomeIcon icon={titleIcon} className={`mr-2 ${textColor}`}/>
            {title}
        </h3>
        <p className='leading-relaxed'>{text}</p>
        <ul>
            {descList.map((list, i) => (
                <li className='my-1' key={i}><FontAwesomeIcon icon={listIcon} className={`mx-2 ${textColor}`}/>{list}</li>
            ))}
        </ul>
        {
            optionTitle && (
                <h4 className='my-2'>
                    <FontAwesomeIcon icon={optionTitleIcon} className={`mx-2 ${textColor}`} />
                    {optionTitle}
                </h4>

            )
        }
        {
            optionList && (
                <ul>
                    {optionList.map((list, i) => (
                        <li key={i}>
                            <FontAwesomeIcon icon={optionListIcon} className={`mx-2 ${textColor}`} />
                            {list}
                        </li>
                    ))}
                </ul>
            )
        }
        {children}
    </motion.div>
  );
};

export default CardDesc;
