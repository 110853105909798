import { faHandPointRight } from '@fortawesome/free-regular-svg-icons'
import { faAngleDoubleRight, faPlusCircle, faTag } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import CardDesc from '../atoms/cardDesc'
import CardHeader from '../atoms/cardHeader'

const CostCard = ({
    cardTitle,
    cost,
    descTitle,
    descText,
    textColor,
    bgColor,
    borderColor,
    descList,
    optTitle,
    optList,
    children
}) => {
    return (
        <div className='mb-4 rounded border border-gray-400 shadow-lg w-full'>
            <CardHeader title={cardTitle} subTitle={cost} bgColor={bgColor} />
            <CardDesc
                title={descTitle}
                text={descText}
                borderColor={borderColor}
                textColor={textColor}
                titleIcon={faTag}
                descList={descList}
                listIcon={faHandPointRight}
                optionTitle={optTitle}
                optionTitleIcon={faAngleDoubleRight}
                optionList={optList}
                optionListIcon={faPlusCircle}
            >
                {children}
            </CardDesc>
        </div>
    )
}

export default CostCard
