import Layout from '@/components/Layout'
import ArticleTemp from '@/components/modules/articleTemp'
import SEO from '@/components/seo'
import React from 'react'
import CostCard from '../components/blocks/costCard'

const Cost = () => {
    return (
        <Layout>
            <SEO title={`相続登記費用`} description={`相続登記費用について、複数プランをご用意させていただいております。お客様のニーズにあったプランをご選択いただけますので、是非ご相談ください。`} />
            <div className='w-11/12 my-4 mx-auto'>
                <ArticleTemp header={`相続登記費用について`} descText={`以下の料金は、報酬です。登録免許税、戸籍収集費用、郵送料等の実費は含まれておりませんので、ご注意ください。`}>
                    <div className='md:flex md:flex-evenly md:gap-3 my-4' >
                        <CostCard
                            cardTitle={`セルフプラン`}
                            cost={`55,000円〜（税込）`}
                            descTitle={`セルフプランの内容`}
                            descText={`相続登記さえできればいい！急がないし、とにかく安く済ませたい。そんなご要望をお持ちの方向けのプランです。`}
                            descList={['相続人確定の戸籍等収集はご自身でお願いします。', '登記完了後はインターネット取得の登記事項要約書でのご確認となります。', '法定相続登記を原則としたプランになります。']}
                            optTitle={`オプション`}
                            optList={['遺産分割協議書作成', '法定相続情報一覧作成', '戸籍収集', '複数不動産の登記申請']}
                            textColor={`text-lime-400`}
                            bgColor={'bg-lime-400'}
                            borderColor={`border-lime-400`}
                        >    
                        </CostCard>
                        <CostCard
                            cardTitle={`スタンダードプラン`}
                            cost={`88,000円〜（税込）`}
                            descTitle={`スタンダードプランの内容`}
                            descText={`標準的な相続登記のプランとなります。`}
                            descList={['相続登記に必要な戸籍収集等を行います。', '登記申請に必要な遺産分割協議書の作成を行います。']}
                            optTitle={`オプション`}
                            optList={['法定相続情報一覧作成', '複数不動産の登記申請']}
                            textColor={`text-amber-400`}
                            bgColor={'bg-amber-400'}
                            borderColor={`border-amber-400`}
                        >    
                        </CostCard>
                        
                        
                    </div>
                </ArticleTemp>
                <ArticleTemp
                    header={`その他相続関連の費用について`} 
                    descText={`現在、金融機関等の相続手続等にも使えるようになってきている法務局で作成できる、法定相続情報一覧というものがあります。不動産の相続手続きはないけれども、戸籍の収集に自信がないという方は、是非とも法定相続情報作成プランをご検討下さい。`}>
                    
                    <div className='md:flex md:flex-evenly md:gap-3 my-4' >
                        <CostCard
                            cardTitle={`法定相続情報作成プラン`}
                            cost={`27,500円〜（税込）`}
                            descTitle={`法定相続情報作成プランの内容`}
                            descText={`法務局へ法定相続情報一覧図の作成の申請を行います。`}
                            descList={['申請に必要な戸籍収集等を行います。', '多くの金融機関での相続手続等にご使用できます。']}
                            optTitle={``}
                            optList={[]}
                            textColor={`text-teal-400`}
                            bgColor={'bg-teal-400'}
                            borderColor={`border-teal-400`}
                        >    
                        </CostCard>
                        <CostCard
                            cardTitle={`相続放棄支援プラン`}
                            cost={`33,000円〜（税込）`}
                            descTitle={`相続放棄支援プラン内容`}
                            descText={`家庭裁判所へ提出する相続放棄申述書の作成を行います。`}
                            descList={[]}
                            optTitle={``}
                            optList={[]}
                            textColor={`text-cyan-400`}
                            bgColor={'bg-cyan-400'}
                            borderColor={`border-cyan-400`}
                        >    
                        </CostCard>
                            
                        

                            
                            
                        
                    </div>

                </ArticleTemp>
            </div>
        </Layout>
    )
}

export default Cost
